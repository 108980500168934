<template>
  <section>
    <b-overlay
      :show="isAPICallInProgress"
      rounded="sm"
    >
      <b-card>
        <validation-observer ref="editUser">
          <b-form
            v-if="userData"
            class="form-validate"
          >
            <b-row class="mb-2">
              <b-col cols="12">
                <b-button
                  variant="light"
                  @click="() => this.$router.push({name: 'users-list'})"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                    size="15"
                    class="mr-1"
                  />
                  <span class="text-nowrap">Voltar</span>
                </b-button>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col cols="12">
                <!-- <b-avatar
                size="60"
                :style="{backgroundColor: `${getInitialsUser.bgColor}` }"
              >
                <span>{{ getInitialsUser.initials }}</span>
              </b-avatar> -->

                <div class="container-avatar">
                  <div
                    class="avatar-box"
                    :style="{backgroundColor: `${getInitialsUser.bgColor}` }"
                  >
                    <span>{{ getInitialsUser.initials }}</span>
                  </div>
                  <div>
                    <h4>{{ userData.name }}</h4>
                    <b-button
                      variant="primary"
                      @click.prevent="validationForm"
                    >
                      <span class="text-nowrap">Salvar</span>
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col
                cols="12"
                class="my-2"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="UserIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">Informações do usuário</span>
                  </b-card-title>
                </b-card-header>
              </b-col>
              <b-col
                cols="4"
              >
                <b-form-group
                  label="Nome*"
                  label-for="username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="nome"
                    rules="required"
                  >
                    <b-form-input
                      v-model="userData.name"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      placeholder="Eleanor Aguiar"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
              >
                <b-form-group
                  label="Email*"
                  label-for="emailUser"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="userData.email"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      placeholder="eleanoraguiar@gmail.com"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
              >
                <b-form-group
                  label="CPF*"
                  label-for="cpf"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cpf"
                    rules="required"
                  >
                    <cleave
                      id="cpf"
                      v-model="userData.cpf"
                      class="form-control"
                      :raw="false"
                      :options="cpfMask"
                      :state="errors.length > 0 ? false:null"
                      placeholder="xxx.xxx.xxx-xx"
                    />
                    <!-- <b-form-input
                    v-model="userData.cpf"
                    :state="errors.length > 0 ? false:null"
                    type="text"
                    placeholder="xxx.xxx.xxx-xx"
                    disabled
                  /> -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
              >
                <b-form-group
                  label="Telefone"
                  label-for="telefone"
                >
                  <!-- <validation-provider
                  #default="{ errors }"
                  name="telefone"
                  rules="required"
                > -->
                  <cleave
                    id="cellPhone"
                    v-model="userData.cellPhone"
                    class="form-control"
                    :raw="false"
                    :options="cellPhoneMask"
                    placeholder="(xx) xxxxx-xxxx"
                  />
                <!-- <b-form-input
                    v-model="userData.cellPhone"
                    :state="errors.length > 0 ? false:null"
                    type="text"
                    placeholder="xx xxxxx-xxxx"
                  /> -->
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
                </b-form-group>
              </b-col>

              <b-col
                cols="4"
              >
                <validation-observer ref="profileValidation">
                  <b-form
                    class="form-validate"
                  >
                    <b-form-group
                      label="Escolha o perfil"
                      label-for="profileName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="nome de perfil"
                        rules="required"
                      >
                        <v-select
                          v-model="selected"
                          label="name"
                          :options="optionsProfileSelect"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-form>
                </validation-observer>

              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <!-- PERMISSION TABLE -->
                <b-card
                  no-body
                  class="border mt-1"
                >
                  <b-row class="pt-2">
                    <b-col cols="12">
                      <b-card-header class="p-1">
                        <b-card-title class="font-medium-2">
                          <feather-icon
                            icon="LockIcon"
                            size="18"
                          />
                          <span class="align-middle ml-50">Permissões</span>
                        </b-card-title>
                      </b-card-header>
                    </b-col>

                    <b-col cols="12">
                      <b-table
                        striped
                        responsive
                        class="mb-0"
                        :items="permissionsData"
                        :fields="tableColumns"
                      >
                        <template #cell(featureName)="data">
                          {{ data.value }}
                        </template>
                        <template #cell(edit)="data">
                          <b-form-checkbox
                            :checked="data.value"
                            disabled
                          />
                        </template>
                        <template #cell(read)="data">
                          <b-form-checkbox
                            :checked="data.value"
                            disabled
                          />
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>

                </b-card>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      <!--  -->

      </b-card>
    </b-overlay>

  </section>

</template>

<script>

import { mapGetters } from 'vuex'

import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BButton, BCardHeader, BCardTitle, BFormCheckbox, BTable, BOverlay,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { required, email, min } from '@validations'

import vSelect from 'vue-select'

import Cleave from 'vue-cleave-component'
import removeSpecialChars from '@/utils/removeSpecialChars'

import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import userStoreModule from '../userStoreModule'

// import { ref } from '@vue/composition-api'

export default {
  components: {
    BRow,
    BCol,
    // BImg,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BTable,
    vSelect,
    // BAvatar,
    Cleave,
    // BImg,
    BOverlay,
  },
  data() {
    return {
      required,
      email,
      min,
      username: '',
      emailUser: '',
      cpf: '',
      cellPhone: '',
      //   selected: { name: 'Admin' },
      selected: '',
      profileList: [],
      cpfMask: {
        delimiters: ['.', '.', '-'],
        blocks: [3, 3, 3, 2],
        uppercase: true,
      },
      cellPhoneMask: {
        delimiters: ['(', ')', ' ', '-'],
        blocks: [0, 2, 0, 5, 4],
        uppercase: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
    getInitialsUser() {
      const colors = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50', '#f1c40f', '#e67e22', '#e74c3c', '#95a5a6', '#f39c12', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d']

      const initials = this.userData.name.split(' ').length > 1 ? this.userData.name.split(' ')[0].charAt(0).toUpperCase() + this.userData.name.split(' ')[1].charAt(0).toUpperCase() : this.userData.name.split(' ')[0].charAt(0).toUpperCase()

      const charIndex = initials.charCodeAt(0) - 65
      const colorIndex = charIndex % 19

      const bgColor = colors[colorIndex]

      return {
        initials, bgColor,
      }
    },
  },
  watch: {
    selected() {
      this.resolveSelectedProfile()
    },
  },
  mounted() {
    localize('pt_BR')
    this.fetchUser()
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const userData = ref(null)
    const actualProfile = ref({ name: '' })

    const optionsProfileSelect = ref([])
    const permissionsData = ref([])

    const tableColumns = [
      { key: 'featureName', label: 'Funcionalidade', sortable: false },
      { key: 'edit', label: 'Editar', sortable: false },
      { key: 'read', label: 'Ver', sortable: false },
    ]

    // store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
    //   .then(response => {
    //     userData.value = response.data.result
    //     actualProfile.value = { name: response.data.result.profileName }
    //   })
    //   .catch(error => {
    //     if (error.response.status === 404) {
    //       userData.value = undefined
    //     }
    //   })

    function fetchUser() {
      return new Promise((resolve, reject) => {
        useJwt.getUserSystem({ token: router.currentRoute.params.id })
          .then(response => {
            userData.value = response.data.result
            actualProfile.value = { name: response.data.result.profileName, id: response.data.result.profileId }

            this.fetchProfileList()

            resolve(response)
          })
          .catch(error => reject(error))
      })
    }

    // fetch lista de profiles
    function fetchProfileList() {
      return new Promise((resolve, reject) => {
        // usando a api via jwt
        useJwt.listProfile()
          .then(response => {
            this.profileList = response.data.result

            // this.selected = { name: response.data.result[0].name, id: response.data.result[0].id }
            this.selected = { name: actualProfile.value.name, id: actualProfile.value.id }

            optionsProfileSelect.value = response.data.result.map(profile => ({ name: profile.name, id: profile.id }))

            return resolve(response)
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ocorreu um erro ao tentar listar os perfis. Tente novamente',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
              },
            })
            reject(error)
          })
      })
    }

    function resolveSelectedProfile() {
      const selectedProfileName = this.selected.name
      // qtdUserByProfile.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].usersAssociated
      permissionsData.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].featuresProfiles

      // permissionDataToUpdate.value = this.profileList.filter(profile => profile.name === selectedProfileName)[0].featuresProfiles
    }

    return {
      userData,
      tableColumns,
      actualProfile,
      fetchProfileList,
      fetchUser,
      resolveSelectedProfile,
      optionsProfileSelect,
      permissionsData,
    }
  },
  methods: {
    validationForm() {
      this.$refs.editUser.validate().then(success => {
        if (success) {
          this.formSubmitted()
        }
      })
    },

    formSubmitted() {
      useJwt.updateUserSystem({
        userToken: this.userData.userToken,
        name: this.userData.name,
        cpf: removeSpecialChars(this.userData.cpf),
        email: this.userData.email,
        cellPhone: removeSpecialChars(this.userData.cellPhone),
        profileId: this.selected.id,
        profileName: this.selected.name,
        status: this.userData.status,
      })
        .then(response => {
          this.$router.push({ name: 'users-list' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Usuário atualizado com sucesso!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          return response
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ocorreu um erro ao tentar criar o usuário. Tente novamente',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
            },
          })
          return error
        })

      // console.log(payload)
    },
  },
}
</script>

<style lang="scss" scoped>

@import '@core/scss/vue/libs/vue-select.scss';

    .container-avatar {
      display: flex
    }

    .avatar-box {
      border-radius: 0.5rem;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 7rem;
      width: 7rem;
      display: flex;
      margin: 0 2rem 0 0;

        span {
          font-weight: bold;
          color: #fff;
          font-size: 2rem;
        }
    }
</style>
