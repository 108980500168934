var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-overlay',{attrs:{"show":_vm.isAPICallInProgress,"rounded":"sm"}},[_c('b-card',[_c('validation-observer',{ref:"editUser"},[(_vm.userData)?_c('b-form',{staticClass:"form-validate"},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function () { return this$1.$router.push({name: 'users-list'}); }}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ArrowLeftIcon","size":"15"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("Voltar")])],1)],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"container-avatar"},[_c('div',{staticClass:"avatar-box",style:({backgroundColor: ("" + (_vm.getInitialsUser.bgColor)) })},[_c('span',[_vm._v(_vm._s(_vm.getInitialsUser.initials))])]),_c('div',[_c('h4',[_vm._v(_vm._s(_vm.userData.name))]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Salvar")])])],1)])])],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"my-2",attrs:{"cols":"12"}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Informações do usuário")])],1)],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Nome*","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"Eleanor Aguiar"},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,115754687)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Email*","label-for":"emailUser"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","placeholder":"eleanoraguiar@gmail.com","disabled":""},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3256885715)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"CPF*","label-for":"cpf"}},[_c('validation-provider',{attrs:{"name":"cpf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"cpf","raw":false,"options":_vm.cpfMask,"state":errors.length > 0 ? false:null,"placeholder":"xxx.xxx.xxx-xx"},model:{value:(_vm.userData.cpf),callback:function ($$v) {_vm.$set(_vm.userData, "cpf", $$v)},expression:"userData.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4126779708)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Telefone","label-for":"telefone"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"cellPhone","raw":false,"options":_vm.cellPhoneMask,"placeholder":"(xx) xxxxx-xxxx"},model:{value:(_vm.userData.cellPhone),callback:function ($$v) {_vm.$set(_vm.userData, "cellPhone", $$v)},expression:"userData.cellPhone"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-observer',{ref:"profileValidation"},[_c('b-form',{staticClass:"form-validate"},[_c('b-form-group',{attrs:{"label":"Escolha o perfil","label-for":"profileName"}},[_c('validation-provider',{attrs:{"name":"nome de perfil","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.optionsProfileSelect,"clearable":false},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2823574693)})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-row',{staticClass:"pt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Permissões")])],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","responsive":"","items":_vm.permissionsData,"fields":_vm.tableColumns},scopedSlots:_vm._u([{key:"cell(featureName)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(edit)",fn:function(data){return [_c('b-form-checkbox',{attrs:{"checked":data.value,"disabled":""}})]}},{key:"cell(read)",fn:function(data){return [_c('b-form-checkbox',{attrs:{"checked":data.value,"disabled":""}})]}}],null,false,911573251)})],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }